export const chartarray = [
  { month: "January", emission: 0 },
  { month: "February", emission: 320.3577364206881 },
  { month: "March", emission: 400.44717052586014 },
  { month: "April", emission: 386.44717052586014 },
  { month: "May", emission: 400.44717052586014 },
  { month: "June", emission: 240.26830231551608 },
  { month: "July", emission: 160.17886821034406 },
  { month: "August", emission: 150.17886821034406 },
  { month: "September", emission: 0 },
  { month: "October", emission: 0 },
  { month: "November", emission: 0 },
  { month: "December", emission: 0 },
];
